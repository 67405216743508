import React, { createContext, useState, useEffect } from 'react'
import useSessionStorage from '../hooks/useSessionStorage'
import useLocalStorage from '../hooks/useLocalStorage'
import { products } from '../data/index'
import * as API from '../services/api.service'

// Create context
export const StateContext = createContext()

// Global state provider component that manages the state of the selected products and the wizard.
export default function StateProvider({ children }) {
    // Selected Products Form //
    //---------------------------------------------------------------------------//

    // Shared State
    const [selectedProducts, setSelectedProducts] = useState([])
    const [calculation, setCalculation] = useState([])
    const [otherProducts, setOtherProducts] = useState([])
    const [cart, setCart] = useLocalStorage('cart', [])
    const [showCheckoutButton, setShowCheckoutButton] = useState(false)
    const [oneTimeToken, setOneTimeToken] = useSessionStorage('oneTimeToken', [])
    const [client, setClient] = useSessionStorage('client', [])
    const [registrationEmail, setRegistrationEmail] = useSessionStorage('registrationEmail', [])
    const [registrationFirstName, setRegistrationFirstName] = useSessionStorage('registrationFirstName', [])
    const [registrationLastName, setRegistrationLastName] = useSessionStorage('registrationLastName', [])
    const [personalData, setPersonalData] = useState([])
    const [allConfirmed, setAllConfirmed] = useSessionStorage('allConfirmed', false)
    const [succeeded, setSucceeded] = useState(false)
    const [selectedProductsLocale, setSelectedProductsLocale] = useSessionStorage('selectedProductsLocale', [])
    const [tokenLocale, setTokenLocale] = useSessionStorage('tokenLocale', [])
    const [productsEn, setProductsEn] = useSessionStorage('productsEn', [])
    const [productsFr, setProductsFr] = useSessionStorage('productsFr', [])
    const [productsDe, setProductsDe] = useSessionStorage('productsDe', [])
    const [insuredPerson, setInsuredPerson] = useSessionStorage('insuredPersonData', {})
    const [insuredPersonGender, setInsuredPersonGender] = useState({})
    const [premiumPayer, setPremiumPayer] = useSessionStorage('premiumPayerData', {})
    const [premiumPayerGender, setPremiumPayerGender] = useState({})

    const [customerDetails, setCustomerDetails] = useSessionStorage('customerDetails', {})
    const [customerDetailsWithMI, setCustomerDetailsWithMI] = useSessionStorage('customerDetailsMI', {})

    // User Data States
    const [email, setEmail] = useState({})
    const [password, setPassword] = useState({})
    const [gender, setGender] = useState({})
    const [firstName, setFirstName] = useState({})
    const [lastName, setLastName] = useState({})
    const [city, setCity] = useState({})
    const [canton, setCanton] = useState(null)
    const [zipCode, setZipCode] = useState({})
    const [street, setStreet] = useState({})
    const [phoneCountry, setPhoneCountry] = useState({})
    const [phoneNumber, setPhoneNumber] = useState({})
    const [dateOfBirth, setDateOfBirth] = useState({})
    const [nationality, setNationality] = useState({ value: 999 })
    const [countries, setCountries] = useState({})
    const [phoneCountries, setPhoneCountries] = useState({})
    const [registrationError, setRegistrationError] = useState(false)
    const [registrationMessageError, setRegistrationMessageError] = useState(false)
    const [ agreeTerms, setAgreeTerms ] = useSessionStorage('agreeTerms', {})
    const [ agreePrivacyPolicy, setAgreePrivacyPolicy ] = useSessionStorage('agreePrivacyPolicy', {})
    const [ agreeTermProducts, setAgreeTermProducts ] = useSessionStorage('agreeTermProducts', {})

    const [newsletter, setNewsletter] = useState({ id: 'newsletter', value: false })
    const [legalTerms, setLegalTerms] = useState({ id: 'accept-legal', value: false })
    const [dataTerms, setDataTerms] = useState({ id: 'accept-data', value: false })
    const [schoolFeeAcceptTerms, setSchoolFeeAcceptTerms] = useState({ id: 'school-fee-terms', value: false })
    const [travelInsuranceAcceptTerms, setTravelInsuranceAcceptTerms] = useState({
        id: 'travel-insurance-terms',
        value: false
    })
    const [cyberSecurityAcceptTerms, setCyberSecurityAcceptTerms] = useState({
        id: 'cyber-security-terms',
        value: false
    })
    const [legalProtectionAcceptTerms, setLegalProtectionAcceptTerms] = useState({
        id: 'legal-protection-terms',
        value: false
    })
    const [householdAcceptTerms, setHouseholdAcceptTerms] = useState({ id: 'household-terms', value: false })
    const [personalLiabilityAcceptTerms, setPersonalLiabilityAcceptTerms] = useState({
        id: 'personal-liability-terms',
        value: false
    })
    // SetAllConfirmed Function
    // This function is used to handle the confirmations for the cart items.
    // It takes in two parameters: id and validDate.
    // The id parameter is the id of the cart item that is being confirmed.
    // The validDate parameter is a boolean that indicates whether the date is valid or not.
    const handleConfirmations = (id, validDate) => {
        cart?.data.forEach(cartItem => {
            if (cartItem.id === id) {
                cartItem.confirmed = validDate
            }
        })
        // This line checks if all cart items are confirmed.
        // If they are, it sets allConfirmed to true.
        const allConfirmed = cart?.data.every(cartItem => cartItem.confirmed)
        setAllConfirmed(allConfirmed)
    }

    // Callback
    // Adds or removes a product from the selected products list.
    const handleAddOrRemove = id => {
        setSelectedProducts(prevSelectedProducts => {
            const updatedSelectedProducts = prevSelectedProducts.includes(id)
                ? prevSelectedProducts.filter(productId => productId !== id)
                : [...prevSelectedProducts, id].sort((a, b) => a - b)

            // Sync state to local storage
            setSelectedProductsLocale(updatedSelectedProducts)
            return updatedSelectedProducts
        })
    }

    const getProductID = id => {
        const product = products.find(entry => entry.id === id)
        return product ? product.id : null
    }

    // Form Inputs object array state hook — to collect field objects in an array based on Seleted Product productID.
    function useFormState(productID, sessionKey) {
        const [state, setState] = useState({ productID, fields: [] })
        const [sessionState, setSessionState] = useSessionStorage(sessionKey, {
            productID,
            fields: []
        })

        const addOrRemove = item => {
            setState(prevState => {
                const index = prevState.fields.findIndex(stateItem => stateItem.id === item.id)
                const updatedState =
                    index === -1
                        ? { productID: prevState.productID, fields: [...prevState.fields, item] }
                        : {
                              productID: prevState.productID,
                              fields: [...prevState.fields.slice(0, index), item, ...prevState.fields.slice(index + 1)]
                          }

                // sync State to Session Storage
                setSessionState(updatedState)
                return updatedState
            })
        }

        // sync Session Storage to State
        useEffect(() => {
            if (typeof Storage !== 'undefined') {
                setState(sessionState)
            }
        }, [sessionState])

        // Add a reset function
        const reset = () => {
            const initialState = { productID, fields: [] }
            setState(initialState)
            setSessionState(initialState)
        }

        return [state, addOrRemove, sessionState, reset]
    }

    // Fetch products from the API
    const fetchProducts = async (lang, setProducts) => {
        try {
            const response = await API.getProducts(lang)
            setProducts(response)
        } catch (error) {
            console.error(error)
        }
    }

    // Fetch products for product page slugs if they are not in the session storage
    useEffect(() => {
        if (productsEn.length === 0) {
            fetchProducts('en', setProductsEn).then(() => {})
        }
        if (productsFr.length === 0) {
            fetchProducts('fr', setProductsFr).then(() => {})
        }
        if (productsDe.length === 0) {
            fetchProducts('de', setProductsDe).then(() => {})
        }
    }, [])

    // Effects
    // Sync selectedProducts with selectedProductsLocale on page refresh
    useEffect(() => {
        setSelectedProducts(selectedProductsLocale)
    }, [selectedProductsLocale])

    // Household Form state //
    const [householdState, householdAddOrRemove, householdStateSession, resetHousehold] = useFormState(
        getProductID(9),
        'householdStateSession'
    )

    // Household Confirmation Form state //
    const [
        householdConfirmationState,
        householdConfirmationAddOrRemove,
        householdConfirmationStateSession,
        resetHouseholdConfirmation
    ] = useFormState(getProductID(9), 'householdConfirmationStateSession')

    // Liability Form state //
    const [liabilityState, liabilityAddOrRemove, liabilityStateSession, resetLiability] = useFormState(
        getProductID(10),
        'liabilityStateSession'
    )

    // Liability Confirmation Form state //
    const [
        liabilityConfirmationState,
        liabilityConfirmationAddOrRemove,
        liabilityConfirmationStateSession,
        resetLiabilityConfirmation
    ] = useFormState(getProductID(10), 'liabilityConfirmationStateSession')

    // School Fee Form state //
    const [schoolState, schoolAddOrRemove, schoolStateSession, resetSchool] = useFormState(
        getProductID(5),
        'schoolStateSession'
    )

    // School Fee Confirmation Form state //
    const [
        schoolConfirmationState,
        schoolConfirmationAddOrRemove,
        schoolConfirmationStateSession,
        resetSchoolConfirmation
    ] = useFormState(getProductID(5), 'schoolConfirmationStateSession')

    // Travel Insurance Form state //
    const [travelState, travelAddOrRemove, travelStateSession, resetTravel] = useFormState(
        getProductID(6),
        'travelStateSession'
    )

    // Travel Insurance Confirmation Form state //
    const [
        travelConfirmationState,
        travelConfirmationAddOrRemove,
        travelConfirmationStateSession,
        resetTravelConfirmation
    ] = useFormState(getProductID(6), 'travelConfirmationStateSession')

    // Cyber Insurance Form state //
    const [cyberState, cyberAddOrRemove, cyberStateSession, resetCyber] = useFormState(
        getProductID(7),
        'cyberStateSession'
    )

    // Cyber Insurance Confirmation Form state //
    const [
        cyberConfirmationState,
        cyberConfirmationAddOrRemove,
        cyberConfirmationStateSession,
        resetCyberConfirmation
    ] = useFormState(getProductID(7), 'cyberConfirmationStateSession')

    // Legal Form state //
    const [legalState, legalAddOrRemove, legalStateSession, resetLegal] = useFormState(
        getProductID(8),
        'legalStateSession'
    )

    // Legal Confirmation Form state //
    const [
        legalConfirmationState,
        legalConfirmationAddOrRemove,
        legalConfirmationStateSession,
        resetLegalConfirmation
    ] = useFormState(getProductID(8), 'legalConfirmationStateSession')

    const resetAllStates = () => {
        setSelectedProducts([])
        setCalculation([])
        setOtherProducts([])
        setCart([])
        setPassword({})
        setRegistrationError(false)
        setRegistrationMessageError(false)
        setShowCheckoutButton(false)
        setAllConfirmed(false)
        setSucceeded(false)
        setOneTimeToken([])
        setRegistrationEmail([])
        setRegistrationFirstName([])
        setRegistrationLastName([])
        setPersonalData([])
        setSelectedProductsLocale([])
        setEmail({})
        setGender({})
        setFirstName({})
        setLastName({})
        setCity({})
        setZipCode({})
        setStreet({})
        setPhoneCountry({})
        setPhoneNumber({})
        setDateOfBirth({})
        setCountries({})
        setPhoneCountries({})
        setNewsletter({ id: 'newsletter', value: false })
        setLegalTerms({ id: 'accept-legal', value: false })
        setDataTerms({ id: 'accept-data', value: false })
        setSchoolFeeAcceptTerms({ id: 'school-fee-terms', value: false })
        setTravelInsuranceAcceptTerms({id: 'travel-insurance-terms', value: false})
        setCyberSecurityAcceptTerms({id: 'cyber-security-terms', value: false})
        setLegalProtectionAcceptTerms({id: 'legal-protection-terms', value: false})
        setHouseholdAcceptTerms({ id: 'household-terms', value: false })
        setPersonalLiabilityAcceptTerms({id: 'personal-liability-terms', value: false})
        resetHousehold()
        resetHouseholdConfirmation()
        resetLiability()
        resetLiabilityConfirmation()
        resetSchool()
        resetSchoolConfirmation()
        resetTravel()
        resetTravelConfirmation()
        resetCyber()
        resetCyberConfirmation()
        resetLegal()
        resetLegalConfirmation()
        setInsuredPerson({})
        setInsuredPersonGender({})
        setPremiumPayer({})
        setPremiumPayerGender({})
        setCustomerDetails({})
        setCustomerDetailsWithMI({})
        setAgreeTerms({})
        setAgreePrivacyPolicy({})
        setAgreeTermProducts({})
    }

    return (
        // Provide States and Callbacks to children
        <StateContext.Provider
            value={{
                selectedProducts,
                setSelectedProducts,
                handleAddOrRemove,
                selectedProductsLocale,
                calculation,
                setCalculation,
                otherProducts,
                setOtherProducts,
                tokenLocale,
                setTokenLocale,
                cyberState,
                cyberAddOrRemove,
                cyberStateSession,
                cyberConfirmationState,
                cyberConfirmationAddOrRemove,
                cyberConfirmationStateSession,
                schoolState,
                schoolAddOrRemove,
                schoolStateSession,
                schoolConfirmationState,
                schoolConfirmationAddOrRemove,
                schoolConfirmationStateSession,
                travelState,
                travelAddOrRemove,
                travelStateSession,
                travelConfirmationState,
                travelConfirmationAddOrRemove,
                travelConfirmationStateSession,
                legalState,
                legalAddOrRemove,
                legalStateSession,
                legalConfirmationState,
                legalConfirmationAddOrRemove,
                legalConfirmationStateSession,
                householdState,
                householdAddOrRemove,
                householdStateSession,
                householdConfirmationState,
                householdConfirmationAddOrRemove,
                householdConfirmationStateSession,
                liabilityState,
                liabilityAddOrRemove,
                liabilityStateSession,
                liabilityConfirmationState,
                liabilityConfirmationAddOrRemove,
                liabilityConfirmationStateSession,
                cart,
                setCart,
                personalData,
                setPersonalData,
                handleConfirmations,
                allConfirmed,
                setAllConfirmed,
                oneTimeToken,
                setOneTimeToken,
                client,
                setClient,
                registrationEmail,
                setRegistrationEmail,
                registrationFirstName,
                setRegistrationFirstName,
                registrationLastName,
                setRegistrationLastName,
                showCheckoutButton,
                setShowCheckoutButton,
                succeeded,
                setSucceeded,
                email,
                setEmail,
                password,
                setPassword,
                gender,
                setGender,
                firstName,
                setFirstName,
                lastName,
                setLastName,
                city,
                setCity,
                canton,
                setCanton,
                zipCode,
                setZipCode,
                street,
                setStreet,
                phoneCountry,
                setPhoneCountry,
                phoneNumber,
                setPhoneNumber,
                dateOfBirth,
                setDateOfBirth,
                nationality,
                setNationality,
                countries,
                setCountries,
                phoneCountries,
                setPhoneCountries,
                registrationError,
                setRegistrationError,
                registrationMessageError,
                setRegistrationMessageError,
                newsletter,
                setNewsletter,
                legalTerms,
                setLegalTerms,
                dataTerms,
                setDataTerms,
                schoolFeeAcceptTerms,
                setSchoolFeeAcceptTerms,
                travelInsuranceAcceptTerms,
                setTravelInsuranceAcceptTerms,
                cyberSecurityAcceptTerms,
                setCyberSecurityAcceptTerms,
                legalProtectionAcceptTerms,
                setLegalProtectionAcceptTerms,
                householdAcceptTerms,
                setHouseholdAcceptTerms,
                personalLiabilityAcceptTerms,
                setPersonalLiabilityAcceptTerms,
                resetAllStates,
                productsEn,
                setProductsEn,
                productsFr,
                setProductsFr,
                productsDe,
                setProductsDe,
                productsEn,
                setProductsEn,
                productsFr,
                setProductsFr,
                productsDe,
                setProductsDe,
                insuredPerson,
                setInsuredPerson,
                insuredPersonGender,
                setInsuredPersonGender,
                premiumPayer,
                setPremiumPayer,
                premiumPayerGender,
                setPremiumPayerGender,
                customerDetails,
                setCustomerDetails,
                customerDetailsWithMI,
                setCustomerDetailsWithMI,
                agreeTerms,
                setAgreeTerms,
                agreePrivacyPolicy,
                setAgreePrivacyPolicy,
                agreeTermProducts,
                setAgreeTermProducts,
            }}
        >
            {children}
        </StateContext.Provider>
    )
}
