import axios from 'axios'
// import { i18next } from 'gatsby-plugin-react-i18next'

const getLocale = () =>
    typeof window !== 'undefined' ? localStorage.getItem('gatsby-i18next-language') : 'default_locale'

/**
 * PRODUCT RELATED ENDPOINTS
 */
export const getProducts = async lang => {
    try {
        const response = await axios.get(`${process.env.GATSBY_API_URL}/api/products`, {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                locale: lang ? lang : getLocale()
            }
        })
        return response.data.data
    } catch (error) {
        console.error(`Error fetching products: ${error}`)
        return null
    }
}

export const getFaqs = async () => {
    try {
        const response = await axios.get(`${process.env.GATSBY_API_URL}/api/faqs/non-specific`, {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                locale: getLocale()
            }
        })
        return response.data.data
    } catch (error) {
        console.error(`Error fetching products: ${error}`)
        return null
    }
}

export const getCountries = async () => {
    try {
        const response = await axios.get(`${process.env.GATSBY_API_URL}/api/products/countries`, {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                locale: getLocale()
            }
        })
        return response.data.data
    } catch (error) {
        console.error(`Error fetching countries: ${error}`)
        return null
    }
}

/**
 * CART
 */

export const setSelectedProducts = async (selectedProducts, token) => {
    try {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/cart/product-step`,
            {
                data: selectedProducts
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    token,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error setting selected products: ${error}`)
        return null
    }
}

const getField = (state, id) => {
    return state.fields.find(entry => entry.id === id)
}

// const getDate = (state, id) => {
//     let field = state.fields.find(entry => entry.id === id)
//     return field.year + '-' + field.month + '-' + field.day
// }

export const calculate = async (
    token,
    schoolStateSession,
    travelStateSession,
    cyberStateSession,
    legalStateSession,
    householdStateSession,
    liabilityStateSession
) => {
    const calculateObj = {
        9: {
            theft: getField(householdStateSession, 1) ? getField(householdStateSession, 1).value : null,
            amount: getField(householdStateSession, 2) ? getField(householdStateSession, 2).value : null,
            canton: getField(householdStateSession, 3) ? getField(householdStateSession, 3).value : null
        },
        10: {
            type: getField(liabilityStateSession, 1) ? getField(liabilityStateSession, 1).value : null
        },
        5: {
            amount: schoolStateSession.fields.length > 0 ? schoolStateSession.fields[0].value : null,
            school_name: schoolStateSession.fields.length > 0 ? schoolStateSession.fields[2].value : null,
        },
        6: {
            type: travelStateSession.fields.length > 0 ? travelStateSession.fields[0].value : null
        },
        7: {
            type: cyberStateSession.fields.length > 0 ? cyberStateSession.fields[0].value : null
        },
        8: {
            type: legalStateSession.fields.length > 0 ? legalStateSession.fields[0].value : null
        }
    }
    try {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/cart/calculate`,
            {
                data: calculateObj
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    token: token,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in axios request: ${error}`)
        return null
    }
}

export const getCalculation = async token => {
    try {
        const response = await axios.get(`${process.env.GATSBY_API_URL}/api/cart/calculation`, {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                token: token,
                locale: getLocale()
            }
        })
        return response.data.data
    } catch (error) {
        console.error(`Error in getCalculation: ${error}`)
        return null
    }
}

export const getSelectedProducts = async token => {
    try {
        const response = await axios.get(`${process.env.GATSBY_API_URL}/api/cart/products`, {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                token: token,
                locale: getLocale()
            }
        })
        return response.data.data
    } catch (error) {
        console.error(`Error in getSelectedProducts: ${error}`)
        return null
    }
}

export const getOtherProducts = async token => {
    try {
        const response = await axios.get(`${process.env.GATSBY_API_URL}/api/cart/calculate/other`, {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                token: token,
                locale: getLocale()
            }
        })
        return response.data.data
    } catch (error) {
        console.error(`Error in getOtherProducts: ${error}`)
        return null
    }
}

export const cart = async token => {
    try {
        const response = await axios.get(`${process.env.GATSBY_API_URL}/api/cart`, {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                token: token,
                locale: getLocale()
            }
        })
        return response.data
    } catch (error) {
        console.error(`Error in cart: ${error}`)
        return null
    }
}

export const addToCart = async (id, planId, type, token) => {
    try {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/cart/add`,
            {
                product_id: id,
                plan_id: planId,
                type: type
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    token: token,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in addToCart: ${error}`)
        return null
    }
}

export const setConfirmed = async (id, token, startOfInsurance, endOfInsurance = null) => {

    try {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/cart/confirm`,
            {
                data: {
                    id: id,
                    start_of_insurance: startOfInsurance,
                    end_of_insurance: endOfInsurance
                }
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    token: token,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in setConfirmed: ${error}`)
        return null
    }
}

export const removeCartItem = async (id, token) => {
    try {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/cart/remove`,
            {
                data: id
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    token: token,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in removeCartItem: ${error}`)
        return null
    }
}

export const getDownloadableQuote = async (token, productId, isOtherProduct = false) => {
    try {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/cart/downloadQuote`,
            {
                other: isOtherProduct,
                product_id: productId
            },
            {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    token: token,
                    locale: getLocale(),
                    Accept: 'application/pdf'
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in getDownloadableQuote: ${error}`)
        throw error
    }
}

/**
 * PAYMENT
 */

export const payment = async (email, payment_method_id, token) => {
    try {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/payment`,
            {
                email: email,
                payment_method_id: payment_method_id
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    token: token,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in payment: ${error}`)
        return null
    }
}

export const saveOrderItems = async (email, subscriptionId, token, payment_type, customer_details) => {

    const response = await axios.post(
        `${process.env.GATSBY_API_URL}/api/order/save-order-items`,
        {
            email: email,
            subscription_id: subscriptionId,
            payment_type,
            customer_details: customer_details,
        },
        {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                token: token,
                locale: getLocale()
            }
        }
    )
    return response.data

}

/**
 * AUTH
 */

export const saveDob = async (dateOfBirth, token) => {
    try {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/users/save-dob`,
            {
                dob: dateOfBirth.year + '-' + dateOfBirth.month + '-' + dateOfBirth.day
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    token: token,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in saveDob: ${error}`)
        return null
    }
}

export const validateUser = async (fields, authtoken, token) => {
    try {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/validate/customerDetails`,
            {
                ...fields
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    authtoken: authtoken,
                    token: token,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in validation: ${error}`)
        return error.response.data
    }
}

export const acceptTerms = async (terms_date, customer_details, token) => {
    try {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/users/accept-terms`,
            {
                terms_date,
                customer_details,
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    token: token,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in register: ${error}`)
        return null
    }
}

export const saveClientSession = async (input, token) => {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/users/save-client-session`,
            {
                input
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    token: token,
                    locale: getLocale()
                }
            }
        )

        return response.data
}

export const activate = async token => {
    try {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/users/activation`,
            {
                token
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    token: token,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in activate: ${error}`)
        return null
    }
}

export const login = async (email, password) => {
    try {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/users/login`,
            {
                email: email.value,
                password: password.value
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in login: ${error}`)
        return error
    }
}

export const login2fa = async (token, onetimetoken) => {
    try {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/users/login2fa`,
            {
                token: token.value,
                onetimetoken: onetimetoken.onetimetoken
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in login2fa: ${error}`)
        return null
    }
}

export const getCustomerDetails = async (authtoken, token) => {
    try {
        const response = await axios.get(`${process.env.GATSBY_API_URL}/api/users/customer-details`, {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                authtoken: authtoken,
                token: token,
                locale: getLocale()
            }
        })
        return response.data
    } catch (error) {
        console.error(`Error in getCustomerDetails: ${error}`)
        return error
    }
}

export const updatePolicyholderDetails = async (authtoken, token, policyholderDetails) => {
    try {
        const response = await axios.post(`${process.env.GATSBY_API_URL}/api/users/update-policyholder-details`, policyholderDetails, {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                authtoken: authtoken,
                locale: getLocale(),
                token: token
            }
        })
        return response.data
    } catch (error) {
        console.error(`Error in updatePolicyholderDetails: ${error}`)
        return null
    }
}

export const updateCorrespondenceDetails = async (authtoken, token, correspondenceDetails) => {
    try {
        const response = await axios.post(`${process.env.GATSBY_API_URL}/api/users/update-correspondence-details`, correspondenceDetails, {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                authtoken: authtoken,
                locale: getLocale(),
                token: token
            }
        })
        return response.data
    } catch (error) {
        console.error(`Error in updateCorrespondenceDetails: ${error}`)
        return null
    }
}

export const getProfile = async authtoken => {
    try {
        const response = await axios.get(`${process.env.GATSBY_API_URL}/api/users/profile`, {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                authtoken: authtoken,
                locale: getLocale()
            }
        })
        return response.data
    } catch (error) {
        console.error(`Error in getProfile: ${error}`)
        return null
    }
}

export const logout = async authtoken => {
    try {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/users/logout`,
            {
                authtoken: authtoken
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in logout: ${error}`)
        return null
    }
}

export const getGoogleLoginUrl = async () => {
    const response = await axios.get(`${process.env.GATSBY_API_URL}/api/users/google-login-url`, {
        headers: {
            Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
            locale: getLocale()
        }
    })
    return response.data
}

export const googleAuthCallback = async params => {
    const response = await axios.get(`${process.env.GATSBY_API_URL}/api/users/google-login-callback${params}`, {
        headers: {
            Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
            locale: getLocale()
        }
    })
    return response.data
}

export const googleLogin = async (token) => {
    const response = await axios.post(
        `${process.env.GATSBY_API_URL}/api/users/google-login`,
        {
            token
        },
        {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                locale: getLocale()
            }
        }
    )
    return response.data
}

export const getAppleLoginUrl = async () => {
    const response = await axios.get(`${process.env.GATSBY_API_URL}/api/users/apple-login-url`, {
        headers: {
            Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
            locale: getLocale()
        }
    })
    return response.data
}

export const appleAuthCallback = async () => {
    const response = await axios.get(`${process.env.GATSBY_API_URL}/api/users/apple-login-callback`, {
        headers: {
            Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
            locale: getLocale()
        }
    })
    return response.data
}

export const appleLogin = async (token, code) => {
    const response = await axios.post(
        `${process.env.GATSBY_API_URL}/api/users/apple-login`,
        {
            token,
            code
        },
        {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                locale: getLocale()
            }
        }
    )
    return response.data
}

export const getLoginTokenAndRedirect = async (authtoken, tokenLocale) => {
    try {
        // Step 1: Get login token
        const loginTokenResponse = await axios.post(
            `${process.env.GATSBY_API_URL}/api/redirectLoginToken`,
            {
                authtoken: authtoken
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    token: tokenLocale
                }
            }
        )

        // Step 2: Extract login token
        const {
            data: {
                data: { logintoken }
            }
        } = loginTokenResponse

        if (logintoken) {
            // Step 3: Redirect to the other domain with the logintoken
            window.location.href = `${process.env.GATSBY_API_REDIRECT_URL}?token=${logintoken}`
            return true
        } else {
            return false
        }
    } catch (error) {
        console.error(`Error in login token retrieval and redirection process: ${error}`)
        return false
    }
}

export const verifyRecaptcha = async (recaptcha_token, version) => {
    try {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/users/verify-recaptcha`,
            {
                recaptcha_token,
                version
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in verifyRecaptcha: ${error}`)
        return null
    }
}

export const contact = async (name, subject, email, file, message) => {
    const response = await axios.post(
        `${process.env.GATSBY_API_URL}/api/contact`,
        {
            name,
            subject,
            email,
            file,
            message
        },
        {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                locale: getLocale()
            }
        }
    )
    return response.data
}

/**
 * STRIPE
 */
export const getPublicStripeKey = async token => {
    try {
        const response = await axios.get(`${process.env.GATSBY_API_URL}/api/payment/public-key`, {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                token: token,
                locale: getLocale()
            }
        })
        return response.data
    } catch (error) {
        console.error(`Error in getPublicStripeKey: ${error}`)
        return null
    }
}

export const createSubscription = async (token, email, payment_method_id, newsletter) => {
    const response = await axios.post(
        `${process.env.GATSBY_API_URL}/api/payment/subscription`,
        {
            email,
            payment_method_id,
            //TODO
            payment: 'cc',
            save_card: true,
            newsletter
        },
        {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                token: token,
                locale: getLocale()
            }
        }
    )
    return response.data
}

export const createPaymentIntent = async (token, email, first_name, last_name, payment_method_id) => {
    try {
        const response = await axios.post(
            `${process.env.GATSBY_API_URL}/api/payment/payment-intent`,
            {
                email,
                first_name,
                last_name,
                payment_method_id
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                    token: token,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in createPaymentIntent: ${error}`)
        return null
    }
}

/**
 * Terms
 */

export const getTerms = async token => {
    try {
        const response = await axios.get(`${process.env.GATSBY_API_URL}/api/terms`, {
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_API_TOKEN}`,
                token: token,
                locale: getLocale()
            }
        })
        return response.data
    } catch (error) {
        console.error(`Error in getSelectedProducts: ${error}`)
        return null
    }
}

/**
 * One Time Passcode
 */

export const requestPasscode = async (token, email) => {
    try {
        const response = await axios.post(`${ process.env.GATSBY_API_URL }/api/users/login-by-otp`,
            { email },
            {
                headers: {
                    Authorization: `Bearer ${ process.env.GATSBY_API_TOKEN }`,
                    token: token,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in requestPasscode: ${ error }`)
        return error.response.data
    }
}

export const validatePasscode = async (token, email, otp) => {
    try {
        const response = await axios.post(`${ process.env.GATSBY_API_URL }/api/users/validate-otp`,
            { email, otp },
            {
                headers: {
                    Authorization: `Bearer ${ process.env.GATSBY_API_TOKEN }`,
                    token: token,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in validatePasscode: ${ error }`)
        return error.response.data
    }
}

export const resendPasscode = async (token, email) => {
    try {
        const response = await axios.post(`${ process.env.GATSBY_API_URL }/api/users/resend-otp`,
            { email },
            {
                headers: {
                    Authorization: `Bearer ${ process.env.GATSBY_API_TOKEN }`,
                    token: token,
                    locale: getLocale()
                }
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error in resendPasscode: ${ error }`)
        return error
    }
}
